import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DigitalPhotoExamples from "./DigitalPhotoExamples";

const DigitalPhotoRequirements = () => {
  const { t } = useTranslation();

  const photoRequirements = useMemo(() => {
    return {
      digital: [
        t("DIGITAL_PHOTO_REQUIREMENT_1"),
        t("DIGITAL_PHOTO_REQUIREMENT_2"),
        t("DIGITAL_PHOTO_REQUIREMENT_3"),
        t("DIGITAL_PHOTO_REQUIREMENT_4"),
        t("DIGITAL_PHOTO_REQUIREMENT_5"),
      ],
      physical: [
        t("PHYSICAL_PHOTO_REQUIREMENT_1"),
        t("PHYSICAL_PHOTO_REQUIREMENT_2"),
      ],
    };
  }, [t]);

  return (
    <>
      <h1>{t("DIGITAL_PHOTO_REQUIREMENTS")}</h1>
      <div>
        <ul>
          {photoRequirements.digital.map((rule, index) => (
            <li key={index}>{rule}</li>
          ))}
        </ul>
      </div>
      <div>
        <span>{t("PHYSICAL_PHOTO_REQUIREMENTS")}</span>
        <ul>
          {photoRequirements.physical.map((rule, index) => (
            <li key={index}>{rule}</li>
          ))}
        </ul>
      </div>
      <DigitalPhotoExamples />
    </>
  );
};

export default DigitalPhotoRequirements;
