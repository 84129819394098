import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "./CheckIcon";
import CloseIcon from "./CloseIcon";

const imagesUrl = `${process.env.REACT_APP_HOST_URL}/assets/images`;

const DigitalPhotoExamples = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  const examples = useMemo(
    () => [
      {
        title: t("DIGITAL_EXAMPLE_TEXT_1"),
        good: {
          src: `${imagesUrl}/white-background-ok.jpg`,
          alt: "White background: Ok",
        },
        wrong: {
          src: `${imagesUrl}/white-background-wrong.jpg`,
          alt: "White background: Wrong",
        },
      },
      {
        title: t("DIGITAL_EXAMPLE_TEXT_2"),
        good: {
          src: `${imagesUrl}/shadow-ok.jpg`,
          alt: "No shadows: Ok",
        },
        wrong: {
          src: `${imagesUrl}/shadow-wrong.jpg`,
          alt: "No shadows: Wrong",
        },
      },
      {
        title: t("DIGITAL_EXAMPLE_TEXT_3"),
        good: {
          src: `${imagesUrl}/glasses-ok.jpg`,
          alt: "No glasses: Ok",
        },
        wrong: {
          src: `${imagesUrl}/glasses-wrong.jpg`,
          alt: "No glasses: Wrong",
        },
      },
      {
        title: t("DIGITAL_EXAMPLE_TEXT_4"),
        good: {
          src: `${imagesUrl}/head-position-ok.jpg`,
          alt: "Correct head position: Ok",
        },
        wrong: {
          src: `${imagesUrl}/head-position-wrong.jpg`,
          alt: "Correct head position: Wrong",
        },
      },
      {
        title: t("DIGITAL_EXAMPLE_TEXT_5"),
        good: {
          src: `${imagesUrl}/facing-camera-ok.jpg`,
          alt: "Neutral face expression and facing the camera: Ok",
        },
        wrong: {
          src: `${imagesUrl}/facing-camera-wrong.jpg`,
          alt: "Neutral face expression and facing the camera: Wrong",
        },
      },
      {
        title: t("DIGITAL_EXAMPLE_TEXT_6"),
        good: {
          src: `${imagesUrl}/headscarf-ok.jpg`,
          alt: "Headscarf placed in a certain way: Ok",
        },
        wrong: {
          src: `${imagesUrl}/headscarf-wrong.jpg`,
          alt: "Headscarf placed in a certain way: Wrong",
        },
      },
    ],
    [t]
  );

  return (
    <div className={`sk-photo-examples ${className ? className : ""}`}>
      {examples.map((example, index) => {
        const { title, good, wrong } = example;

        return (
          <div key={`${title}${index}`} className="sk-photo-examples__example">
            <span className="sk-photo-examples__example__title">{title}</span>
            <div className="sk-photo-examples__example__photos">
              <div className="sk-photo-with-icon">
                <img
                  src={good.src}
                  alt={good.alt}
                  className="sk-photo-example"
                />
                <CheckIcon />
              </div>
              <div className="sk-photo-with-icon">
                <img
                  src={wrong.src}
                  alt={wrong.alt}
                  className="sk-photo-example"
                />
                <CloseIcon />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DigitalPhotoExamples;
