import httpClient from "../httpClient";

const getImage = (id) => {
  return httpClient.get(`ds160/photo-tool/${id}`);
};

const postImage = (id, image) => {
  const formData = new FormData();
  formData.append("fileName", "portrait");
  formData.append("image", image);
  return httpClient.post(`ds160/photo-tool/${id}/upload/image`, formData);
};

const deleteImage = (id) => {
  return httpClient.delete(`ds160/photo-tool/${id}`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getImage,
  postImage,
  deleteImage,
};
