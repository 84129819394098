import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { CROPPER_HEIGHT, OUTPUT_DIM } from "../constants";
import { getCroppedImg, getFileFromURL, getResizedImageUrl } from "../utils";
import Button from "./Button";

const UploadImage = forwardRef(
  (
    {
      loading,
      portraitPath,
      handleDeleteImage,
      handleReadyForCropping,
      handleError,
      handleUploadImage,
      callback,
    },
    ref
  ) => {
    const [sourceImage, setSourceImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [cropSize, setCropSize] = useState({ width: 1, height: 1 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [imageSize, setImageSize] = useState({
      width: 0,
      height: 0,
      aspectRatio: 1,
    });

    const { t } = useTranslation();

    const imageContainerRef = useRef();
    const fileInputRef = useRef();

    useEffect(() => {
      const imageContainer = imageContainerRef?.current;

      const handleResize = () => {
        const imageContainerWidth = imageContainer.clientWidth;
        const { aspectRatio } = imageSize;

        const widthContainer = CROPPER_HEIGHT * aspectRatio;

        if (widthContainer < imageContainerWidth) {
          setImageSize({
            width: widthContainer,
            height: CROPPER_HEIGHT,
            aspectRatio,
          });
          const cropSize = aspectRatio >= 1 ? CROPPER_HEIGHT : widthContainer;
          setCropSize({ width: cropSize, height: cropSize });
        } else {
          setImageSize({
            width: imageContainerWidth,
            height: imageContainerWidth / aspectRatio,
            aspectRatio: aspectRatio,
          });
          const cropSize =
            aspectRatio >= 1
              ? imageContainerWidth / aspectRatio
              : imageContainerWidth;
          setCropSize({ width: cropSize, height: cropSize });
        }
      };

      if (imageContainer) {
        window.addEventListener("resize", handleResize);
      }
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [imageContainerRef, imageSize]);

    useImperativeHandle(ref, () => ({
      resetState() {
        setSourceImage(null);
      },
      async getCroppedImage() {
        try {
          const croppedImageBlobUrl = await getCroppedImg(
            sourceImage,
            croppedAreaPixels,
            0
          );
          let finalCroppedImageUrl = await getResizedImageUrl(
            croppedImageBlobUrl,
            OUTPUT_DIM
          );

          const imageFile = await getFileFromURL(
            finalCroppedImageUrl,
            "portrait.jpg"
          );
          return imageFile;
        } catch (e) {
          console.error(e);
          return e;
        }
      },
    }));

    const handleOnChange = useCallback(
      (event) => {
        const { files } = event.target;

        if (files?.[0]) {
          handleError("");
          setSourceImage(URL.createObjectURL(files[0]));
        }
      },
      [handleError]
    );

    const handleOnMediaLoaded = useCallback(
      (mediaLoaded) => {
        const { naturalWidth, naturalHeight } = mediaLoaded;
        const divWidth = imageContainerRef.current.clientWidth;

        if (naturalWidth < OUTPUT_DIM || naturalHeight < OUTPUT_DIM) {
          handleError(t("IMAGE_DIMENSION_ERROR"));
          handleReadyForCropping(false);
          setSourceImage(null);
          return;
        }

        const naturalAspect = naturalWidth / naturalHeight;

        const width = CROPPER_HEIGHT * naturalAspect;

        if (width < divWidth) {
          setImageSize({
            width,
            height: CROPPER_HEIGHT,
            aspectRatio: naturalAspect,
          });
          const cropSize = naturalAspect >= 1 ? CROPPER_HEIGHT : width;
          setCropSize({ width: cropSize, height: cropSize });
        } else {
          setImageSize({
            width: divWidth,
            height: divWidth / naturalAspect,
            aspectRatio: naturalAspect,
          });
          const cropSize =
            naturalAspect >= 1 ? divWidth / naturalAspect : divWidth;
          setCropSize({ width: cropSize, height: cropSize });
        }

        handleReadyForCropping(true);
      },
      [handleError, handleReadyForCropping, t]
    );

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleOnNext = useCallback(() => {
      callback();
    }, [callback]);

    const { width, height } = imageSize;

    return (
      <div
        ref={imageContainerRef}
        className={`sk-upload-image ${loading ? "sk-loading" : ""}`}
      >
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg,.jpeg"
          hidden
          onChange={handleOnChange}
        />
        <div className="sk-actions">
          {portraitPath ? (
            <Button
              type="button"
              disabled={loading}
              onClick={handleDeleteImage}
              className="sk-upload-image-button delete"
              color="red"
            >
              {t("DELETE_IMAGE")}
            </Button>
          ) : (
            <Button
              type="button"
              disabled={loading}
              onClick={() => fileInputRef.current.click()}
              className="sk-upload-image-button"
            >
              {t("CHOOSE_IMAGE")}
            </Button>
          )}
          {sourceImage && (
            <Button
              type="button"
              disabled={loading}
              onClick={() => handleUploadImage(sourceImage)}
              className="sk-upload-image-button"
            >
              {t("CONFIRM_IMAGE")}
            </Button>
          )}
          <Button
            type="button"
            color="red"
            onClick={handleOnNext}
            disabled={loading || !portraitPath}
          >
            {t("SEND_IMAGE")}
          </Button>
        </div>

        {portraitPath ? (
          <img
            className="sk-portrait-image"
            alt="Portrait"
            src={`${portraitPath}`}
          />
        ) : (
          <>
            {sourceImage && (
              <div className="sk-cropper" style={{ width, height }}>
                <Cropper
                  image={sourceImage}
                  crop={crop}
                  cropSize={cropSize}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onMediaLoaded={handleOnMediaLoaded}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

export default UploadImage;
