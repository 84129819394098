import { initialize } from "../i18n";
import StepPhotoUpload from "./StepPhotoUpload";

let requestSent = false;

const App = ({ callback, language = "en" }) => {
  if (!requestSent) {
    requestSent = true;
    initialize(language);
  }

  return <StepPhotoUpload callback={callback} />;
};

export default App;
