import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";

import "./styles.scss";

export const initialize = ({ element, callback, language }) => {
  element.classList.add("ds160-photo-tool-root");

  const root = createRoot(element);
  root.render(
    <Suspense fallback={null}>
      <App language={language} callback={callback} />
    </Suspense>
  );
};
