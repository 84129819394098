import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DigitalPhotoRequirements from "./DigitalPhotoRequirements";
import UploadImage from "./UploadImage";
import api from "../api";
import { byteArrayToUrl } from "../utils";

const id = new URLSearchParams(window.location.search).get("ucc_app_id");

const StepPhotoUpload = ({ callback }) => {
  const [loading, setLoading] = useState(false);
  const [portraitPath, setPortraitPath] = useState("");
  const [readyForCropping, setReadyForCropping] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const cropperRef = useRef(null);

  const fetchImage = useCallback(async () => {
    try {
      const { data } = await api.portrait.getImage(id);
      if (data?.location) {
        const url = byteArrayToUrl(data.location.data, "image/jpg")
        setPortraitPath(url);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);

  const { t } = useTranslation();

  const handleReadyForCropping = useCallback((readyForCropping) => {
    setReadyForCropping(readyForCropping);
  }, []);

  const handleError = useCallback((errorMsg) => {
    setErrorMsg(errorMsg);
  }, []);

  const handleDeleteImage = useCallback(async () => {
    setLoading(true);
    try {
      await api.portrait.deleteImage(id);
      setPortraitPath("");
      setErrorMsg("");
    } catch (error) {
      console.error(error);
      setErrorMsg(t("PLEASE_TRY_AGAIN"));
    }
    setLoading(false);
  }, [t]);

  const handleUploadImage = useCallback(async () => {
    if (readyForCropping) {
      setLoading(true);
      try {
        const imageFile = await cropperRef.current.getCroppedImage();
        const {
          data: { location: { data } },
        } = await api.portrait.postImage(id, imageFile);
        cropperRef.current.resetState();
        setErrorMsg("");
        const url = byteArrayToUrl(data, "image/jpg")
        setPortraitPath(url);
      } catch (error) {
        console.error(error);
        setErrorMsg(t("PLEASE_TRY_AGAIN"));
      }
      setLoading(false);
    }
  }, [readyForCropping, t]);

  if (!id) {
    alert("Applicant's ID is missing.");
    return null;
  }

  return (
    <div className="photo-tool-container">
      <DigitalPhotoRequirements />
      {errorMsg && <div className="sk-error-msg">{errorMsg}</div>}
      <UploadImage
        loading={loading}
        portraitPath={portraitPath}
        ref={cropperRef}
        handleDeleteImage={handleDeleteImage}
        handleReadyForCropping={handleReadyForCropping}
        handleError={handleError}
        handleUploadImage={handleUploadImage}
        callback={callback}
      />
    </div>
  );
};

export default StepPhotoUpload;
